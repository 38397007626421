import React, { useRef } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";

// Custom Hook
import useWindowSize from "../hooks/useWindowSize";
// Helpers
import { get_shortDescription } from "../functions/functions_dev";
// Components
import BannerTOP from "./Global/Modules/MediaPush/BannerTop";
// Assets
import Arrow from "../assets/images/arrow_slider.svg";
import ImageNotFound from "../assets/images/image-not-found.jpg";

const WrapperTopContent = ({
  dataProp,
  actionsProp,
  pageProp,
  videoImgReplaceProp = null,
  videoTextReplaceProp = null,
}) => {
  // Props
  const data_dev = dataProp;
  const actions = actionsProp;
  const slider = useRef();

  // Description helper
  const getDescription = (element) => {
    return element.id === 39383
      ? element.description.split(".")[0] + "."
      : get_shortDescription(element.description).title;
  };

  // Cover helper
  const getCover = (element) => {
    return element.filter((element) => element.is_front_cover)
      ? element.filter((element) => element.is_front_cover)[0].original
      : ImageNotFound;
  };

  // Hook window size
  const windowSize = useWindowSize();
  // Page
  const page = pageProp;

  // Check actions in home and convert in bool
  const actionsInPage = actions.actions?.filter(
    (action) => action.section.toLowerCase() === page
  )[0];
  const hasActionsPage = actionsInPage
    ? Boolean(actionsInPage.section.toLowerCase() === page)
    : undefined;

  // Filter slider actions by code  and convert in bool
  const sliderAction = actions.actions?.filter(
    (action) =>
      action.small_type === "SIE" && action.section.toLowerCase() === page
  )[0];
  const hasSlider = sliderAction
    ? Boolean(sliderAction.small_type === "SIE")
    : undefined;

  // Filter image actions by code and convert in bool
  const imageAction = actions.actions?.filter(
    (action) =>
      action.small_type === "IEN" && action.section.toLowerCase() === page
  )[0];
  const hasImage = imageAction
    ? Boolean(imageAction.small_type === "IEN")
    : undefined;

  // Filter video actions by code  and convert in bool
  const videoAction = actions.actions?.filter(
    (action) =>
      action.small_type === "VEN" && action.section.toLowerCase() === page
  )[0];
  const hasVideo = videoAction
    ? Boolean(videoAction.small_type === "VEN")
    : undefined;

  // Conditional Render to show sections
  const conditionalRender = () => {
    // has actions in home?
    if (hasActionsPage) {
      // has video?
      if (hasVideo) {
        // has window size > 992
        return windowSize.width > 992 ? (
          <BannerTOP page={page} actions={actions.actions} />
        ) : (
          videoReplaceMobile()
        );
        // has slider?
      } else if (hasSlider) {
        return <BannerTOP page={page} actions={actions.actions} />;
        // has image?
      } else if (hasImage) {
        return <BannerTOP page={page} actions={actions.actions} />;
      } else {
        return staticContent();
      }
    } else {
      return staticContent();
    }
  };

  // Static wrapper gallery
  const staticContent = () => {
    return (
      <>
        {/* <OwlCarousel
                loop={true}
                dots={false}
                center={true}
                items={1}
                margin={0} 
                autoplay={true}
                rewind={true}
                ref={slider}>
                {data_dev.objects.filter(element => element.is_starred_on_web).sort((a,b)=>b.id-a.id).map((element,index) => (
                    <div className="art" key={index}>
                        <div className="container">
                            <img src={getCover(element.photos)} alt={element.name} />
                            <div className="opacity"></div>
                            <div className="text-content position-relative d-flex justify-content-center align-items-end flex-column">
                                <h2>{element.name}</h2>
                                <h4>{element.publication_title}</h4>
                                <p>{getDescription(element)}</p>
                                <Link to={"desarrollo/"+ element.id +"/" + element.name.replace(/ /g,"-").toLowerCase()}>CONOCÉ MÁS</Link>
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
            <div className="arrow__container d-flex justify-content-between">
                <div onClick={() => (slider.current.prev())} 
                     className="cursor-pointer">
                        <img src={Arrow} alt="Anterior" />
                </div>
                <div onClick={() => (slider.current.next())} 
                     className="cursor-pointer ml-4" 
                     style={{transform:"rotate(180deg)"}} >
                        <img src={Arrow} alt="Siguiente" />
                </div>
            </div> */}
        <div className="frame-container">
          <iframe
            width="100%"
            src={
              "https://www.youtube.com/embed/" +
              "DQHeI6UN0f8" +
              "?autoplay=1&mute=1&loop=1&controls=0&rel=0&showinfo=0&playlist=DQHeI6UN0f8"
              //   "?si=70FLk7NqFtQlS8no&modestbranding=1&autohide=1&showinfo=0&controls=0&autoplay=1&mute=1"
            }
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            frameBorder={0}
          ></iframe>
        </div>
      </>
    );
  };

  // Static image + text to replace video on mobile
  const videoReplaceMobile = () => {
    return (
      <div className="video-replace-mobile">
        {videoImgReplaceProp ? (
          <img
            src={videoImgReplaceProp}
            alt={`Imagen fondo ${page}`}
            className="img-replace"
          />
        ) : (
          <img
            src={ImageNotFound}
            alt="Imagen no encontrada"
            className="img-replace"
          />
        )}
        {videoTextReplaceProp ? (
          <div className="text-container-replace d-flex align-items-end justify-content-center">
            <div className="text-wrapper col-10 h-100 d-flex flex-column justify-content-center">
              <h1 className="fs-1 font-0 text-bold">{videoTextReplaceProp}</h1>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  return <>{conditionalRender()}</>;
};

export default WrapperTopContent;

// Component usasge:
// 1- Store access on views pages
// 2- Use wrapper on main pages components
// 3- Pass stored data acceded on views by props
// 4- By default show gallery with developments images

//  <WrapperTopContent
//    actionsProp={actions} -> store.actions
//    dataProp={data_dev} -> store.developments.developments
//    pageProp={'inicio'} -> page to show
//    videoReplaceProp={bgDev} -> image to replace video on mobile
//    videoTextReplaceProp={textReplaceVideo} /> -> text to show on mobile

// Example: usage on home
// on /views/home.jsx
//    1- Get data access from getDevelopmentsAction() and getActionsAction()
// on components/inicio/main.jsx
//    2- Call the WrapperTopContent component, with stored data
